import {DEFAULT_OPTIONS as FIT_FEATURE_DEFAULT_OPTIONS} from '@mapsight/lib-ol/map/fitToFeature';

const clusterFeaturesOptions = {
	distance: 20,
	spreadDistance: 7,
	spreadRadius: 25,
	spreadFeaturesIfCloseToMinResolution: true,
	fitInViewSelections: ['select'],
	fitInViewOptions: {
		...FIT_FEATURE_DEFAULT_OPTIONS,
		duration: 1000,
		maxZoom: 9999,
		nearest: false,
		skipIfInView: false,
	},
};

export default clusterFeaturesOptions;
