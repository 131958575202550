import merge from 'lodash/merge';
import debounce from 'lodash/debounce';

import {siteConfig as uiSiteConfig} from '@mapsight/ui/config';
import {viewSelector} from '@mapsight/ui/store/selectors';
import {
	VIEW_DESKTOP,
	VIEW_FULLSCREEN,
	VIEW_MAP_ONLY,
	VIEW_MOBILE,
} from '@mapsight/ui/config/constants/app';

import createDefaultBrowserPlugins from '@mapsight/ui/plugins/browser-defaults';
import createDefaultServerPlugins from '@mapsight/ui/plugins/server-defaults';

import toggleClass from '@neonaut/lib-js/es/dom/access/toggle-class';

import styleFunction from '../../../../tmp/mapsight-vector-styles/vector-style';

import {render as renderAppleHrefAttribute} from '../../modules/apple-href-attribute';
import {render as renderToggleLocalStorage} from '../../modules/toggle-local-storage';
import {render as renderCarousel} from '../../modules/carousel';

import readSpEnv from '../../helpers/read-sp-env'; // TODO zum Parameter für die Funktion embed machen (nach dem verschieben von readSpEnv)
import baseMapsightConfig from '../config';

let carouselTimeout;

export const partialChangeHook = debounce(
	function handlePartialContentChangeInMapsightUi() {
		renderToggleLocalStorage();
		renderAppleHrefAttribute();

		// delay carousel rendering a bit, to prevent stuttering/flickering when selecting features fast
		if (carouselTimeout) {
			clearTimeout(carouselTimeout);
		}
		carouselTimeout = setTimeout(
			function onPartialContentChangeRenderDelay() {
				renderCarousel();
			},
			600
		);
	},
	200
);

uiSiteConfig.topOffsetForView = (view) =>
	view === VIEW_MOBILE || view === VIEW_DESKTOP ? 100 : 0; // page banner

function onTouchMoveNoScroll(e) {
	e.preventDefault();
}

// used to define defaults specific to projects
/** @type {import('@mapsight/ui/types').CreateOptions} UI_STATE_DEFAULT */
const UI_STATE_DEFAULT = {
	searchInMap: true,
	embeddedMap: false,
	pageTitle: {
		show: false,
	},
	list: {
		showVaryingListInfoOnly: false, // we want to always show list info column
		selectionBehavior: {
			desktop: 'scrollToMap', // always 'scrollToMap' for now
			mobile: 'showInMapOnlyView', // either 'expandInList', 'scrollToMap', 'showInMapOnlyView'
		},
		additionalScrollOffsetSelect: 80,
		highlightOnMouse: 'mainAndIcon',
		selectOnClick: 'mainAndIcon',
		renderGroupAs: 'h3',
	},
	viewToggle: {
		deselectFeaturesOnToggle: false,
	},
	layerSwitcher: {
		internal: {
			grouped: true,
		},
	},
	// currently not supported:
	//	places: [
	//		{
	//			title: 'Wichtige Orte', // TODO: i18n
	//			type: 'group',
	//			entries: [
	//				 // TODO: i18n
	//				{x: 10.523253, y: 52.263278, title: 'Innenstadt/Rathaus/Schloss'},
	//				{x: 10.521635, y: 52.289933, title: 'Eintracht-Stadion'},
	//				{x: 10.519075, y: 52.257309, title: 'Volkswagenhalle'},
	//				{x: 10.538046, y: 52.252361, title: 'Hauptbahnhof/ZOB'}
	//			]
	//		},
	//		{
	//			title: 'Stadtteile', // TODO: i18n
	//			type: 'group',
	//			entries: [ // TODO: i18n
	//				{x: 10.540116, y: 52.243535, title: 'Bebelhof'},
	//				{x: 10.577806, y: 52.341376, title: 'Bevenrode'},
	//				{x: 10.532125, y: 52.320219, title: 'Bienrode'},
	//				{x: 10.479766, y: 52.23288, title: 'Broitzem'},
	//				{x: 10.605169, y: 52.302444, title: 'Dibbesdorf'},
	//				{x: 10.578557, y: 52.25317, title: 'Elmaussicht'},
	//				{x: 10.46844, y: 52.210947, title: 'Geitelde'},
	//				{x: 10.568883, y: 52.280048, title: 'Gliesmarode'},
	//				{x: 10.483164, y: 52.339114, title: 'Harxbüttel'},
	//				{x: 10.539299, y: 52.229636, title: 'Heidberg'},
	//				{x: 10.604733, y: 52.314309, title: 'Hondelage'},
	//				{x: 10.523352, y: 52.264271, title: 'Innenstadt'},
	//				{x: 10.459498, y: 52.285506, title: 'Kanzlerfeld'},
	//				{x: 10.53915, y: 52.304896, title: 'Kralenriede'},
	//				{x: 10.445491, y: 52.267768, title: 'Lamme'},
	//				{x: 10.479324, y: 52.275072, title: 'Lehndorf'},
	//				{x: 10.504762, y: 52.206416, title: 'Leiferde'},
	//				{x: 10.566254, y: 52.245376, title: 'Lindenbergsiedlung'},
	//				{x: 10.56972, y: 52.224048, title: 'Mascherode'},
	//				{x: 10.525346, y: 52.229692, title: 'Melverode'},
	//				{x: 10.526381, y: 52.279068, title: 'Nördliches Ringgebiet'},
	//				{x: 10.496244, y: 52.28653, title: 'Ölper'},
	//				{x: 10.547761, y: 52.267901, title: 'Östliches Ringgebiet'},
	//				{x: 10.560825, y: 52.293525, title: 'Querum'},
	//				{x: 10.582741, y: 52.239006, title: 'Rautheim'},
	//				{x: 10.574151, y: 52.269, title: 'Riddagshausen'},
	//				{x: 10.517239, y: 52.304532, title: 'Rühme'},
	//				{x: 10.500302, y: 52.219565, title: 'Rüningen'},
	//				{x: 10.607287, y: 52.280332, title: 'Schapen'},
	//				{x: 10.540947, y: 52.295827, title: 'Schuntersiedlung'},
	//				{x: 10.511746, y: 52.291817, title: 'Schwarzer Berg'},
	//				{x: 10.532532, y: 52.287809, title: 'Siegfriedviertel'},
	//				{x: 10.45723, y: 52.226201, title: 'Stiddien'},
	//				{x: 10.523781, y: 52.216063, title: 'Stöckheim'},
	//				{x: 10.559753, y: 52.234196, title: 'Südstadt'},
	//				{x: 10.508668, y: 52.337154, title: 'Thune'},
	//				{x: 10.450921, y: 52.240611, title: 'Timmerlah'},
	//				{x: 10.487338, y: 52.308653, title: 'Veltenhof'},
	//				{x: 10.54257, y: 52.257269, title: 'Viewegsgarten'},
	//				{x: 10.441937, y: 52.305026, title: 'Völkenrode'},
	//				{x: 10.593823, y: 52.287914, title: 'Volkmarode'},
	//				{x: 10.557631, y: 52.327033, title: 'Waggum'},
	//				{x: 10.463496, y: 52.306271, title: 'Watenbüttel'},
	//				{x: 10.512118, y: 52.323399, title: 'Wenden'},
	//				{x: 10.50144, y: 52.262844, title: 'Westliches Ringgebiet'},
	//				{x: 10.482097, y: 52.245699, title: 'Weststadt'}
	//			]
	//		}
	//	]
};

/**
 * @param {import('@mapsight/ui/types').CreateOptions} createOptions ui create options
 * @param {import('@mapsight/ui/types').MapsightCoreState} mapsightConfig mapsight core state
 * @returns {{styleFunction: Function, baseMapsightConfig: import('@mapsight/ui/types').MapsightCoreState, createOptions: import('@mapsight/ui/types').CreateOptions}} preset
 */
export default function embed(createOptions = {}, mapsightConfig) {
	let wasInFullscreen = false;

	function noScrollHandler(state, forceAllow) {
		const view = viewSelector(state);
		const isNoScroll =
			(!forceAllow && view === VIEW_MAP_ONLY) || view === VIEW_FULLSCREEN;

		if (!isNoScroll && wasInFullscreen) {
			// TODO: scroll back to map after leaving fullscreen
			//setTimeout(function () {
			//	scrollToMapTop(uiSiteConfig.topOffsetForView(view), true);
			//}, 100);
		} else {
			wasInFullscreen = true;
		}

		toggleClass(
			window.document.documentElement,
			'ms3--map-no-scroll',
			isNoScroll
		);
		window.document[
			isNoScroll ? 'addEventListener' : 'removeEventListener'
		]('touchmove', onTouchMoveNoScroll);
		return isNoScroll;
	}

	return {
		styleFunction: styleFunction,
		baseMapsightConfig: merge({}, baseMapsightConfig, mapsightConfig),
		createOptions: {
			...createOptions,
			partialChangeHook: partialChangeHook,
			plugins: [
				...(typeof window !== 'undefined'
					? createDefaultBrowserPlugins({
							noScroll: {noScrollHandler: noScrollHandler},
					  })
					: createDefaultServerPlugins()),
				...(createOptions.plugins || []),
			],
			uiState: merge({}, UI_STATE_DEFAULT, createOptions.uiState),
			searchUrl:
				createOptions.lang === 'en'
					? '/apps/geo-search/search_en.php'
					: '/apps/geo-search/search.php',
			imagesUrl: readSpEnv('resourcesPath') + '/img/',
		},
	};
}
