/* eslint-disable import/order,import/first */
import {observeState} from '@mapsight/lib-redux/observe-state';
import EditorMixin from '@mapsight/core/mixins/EditorMixin';
import {addFeature} from '@mapsight/core/lib/feature-sources/actions';

import {di} from '@mapsight/core/ol-proxy';
import DrawInteraction from '@mapsight/core/ol-proxy/definitions/interaction/DrawInteraction';
import proj4 from 'proj4';

import embed from './embed';

di.injectDefinitions([DrawInteraction]);

// TODO pointInput müsste verallgemeinert werden und nach @mapsight/ui wandern.
//  die DrawInteraction ist auch für Editor oder Streckenmesser notwendig.
//  Sie sollte nur geladen und eingetragen wenn eines der drei (pointInput, Editor, Streckenmesser) geladen wird.
export default function pointInput(config = {}) {
	const {
		inputSelector, // required
		lang = 'de',
		inputTemplate = 'https://www.braunschweig.de/plan/#lm=${lat}/${lon}',
		initialValue = null,
	} = config || {};
	const mapsightConfig = {};

	let point = null;
	if (initialValue) {
		const regex =
			'^' +
			inputTemplate
				.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
				.replace(/\\\$\\{lat\\}/gi, '([\\d]+(?:.[\\d]+)?)')
				.replace(/\\\$\\{lon\\}/gi, '([\\d]+(?:.[\\d]+)?)') +
			'$';
		const match = new RegExp(regex, 'i').exec(initialValue);
		if (match) {
			const [, lat, lon] = match;
			point = [parseFloat(lon), parseFloat(lat)];
			mapsightConfig.map = {
				view: {center: proj4('WGS84', 'EPSG:3857', point)},
			};
		}
	}

	function hook({store}) {
		const pointInputMixin = new EditorMixin(store, 'pointInput', {
			draw: {active: true, type: 'Point', replacePrevious: true},
		});

		if (point) {
			store.dispatch(
				addFeature(
					'featureSources',
					pointInputMixin.ids.featureSource,
					{
						type: 'Feature',
						id: 'point-input-initial-feature',
						geometry: {
							type: 'Point',
							coordinates: point,
						},
					}
				)
			);
		}

		observeState(
			store,
			pointInputMixin.selectors.features,
			(createdFeatures) => {
				if (!createdFeatures || !createdFeatures.length) {
					return;
				}

				const feature = createdFeatures[0];
				if (feature.geometry && feature.geometry.type === 'Point') {
					const inputValue = inputTemplate
						.replace(/\${lon}/gi, feature.geometry.coordinates[0])
						.replace(/\${lat}/gi, feature.geometry.coordinates[1]);
					[...document.querySelectorAll(inputSelector)].forEach(
						(element) => {
							element.value = inputValue;
						}
					);
				}
			}
		);
	}

	return embed(
		{
			lang: lang,
			plugins: [['presetHook', {afterCreate: hook}]],
			uiState: {
				embeddedMap: true,
				layerSwitcher: {
					show: {internal: true, external: false},
				},
				map: {show: true},
				list: {show: false},
				tagSwitcher: {show: false},
			},
		},
		mapsightConfig
	);
}
