import proj4 from 'proj4';

import {plain} from '@mapsight/ui/config/feature/sources';
import {
	features as featuresLayer,
	metaData,
} from '@mapsight/ui/config/map/layers';

import {set} from '@mapsight/core/lib/base/actions';

import baseMapsightConfig from '../config';

import embed from './embed';
import {createId} from './utils';

export default function staticMap({
	baseLayerName,
	startCoordinates,
	startZoom,
	centerMapsightIcon,
	centerLabel,
	showCenter = false,
	lang = 'de',
	siteTheme = 'default',
	mapTheme = 'default',
}) {
	const mapsightConfig = {
		featureSources: {},
		map: {
			view: {
				center: startCoordinates
					? proj4('WGS84', 'EPSG:3857', startCoordinates)
					: undefined,
				zoom: startZoom ? parseInt(startZoom, 10) : undefined,
			},
			layers: {},
			interactions: null,
		},
	};

	if (baseLayerName) {
		for (const layerId of Object.keys(baseMapsightConfig.map.layers)) {
			const layerConfig = baseMapsightConfig.map.layers[layerId];

			if (layerConfig.metaData && layerConfig.metaData.isBaseLayer) {
				const overrideVisible = layerId === baseLayerName;

				mapsightConfig.map.layers[layerId] = {
					options: {
						visible: overrideVisible,
					},
					metaData: {
						visibleInLayerSwitcher:
							overrideVisible ||
							layerConfig.metaData.visibleInLayerSwitcher,
					},
				};
			}
		}
	}

	if (startCoordinates && showCenter) {
		const id = createId();
		mapsightConfig.featureSources[id] = {
			...plain(),
			data: {
				type: 'FeatureCollection',
				features: [
					{
						id: id,
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: startCoordinates,
						},
						properties: {
							id: id,
							type: 'sharePoint',
							name: centerLabel,
							mapsightIconId: centerMapsightIcon || 'marker',
						},
					},
				],
			},
			ids: [id],
		};
		mapsightConfig.map.layers[id] = featuresLayer(
			id,
			true,
			false,
			metaData('Startpunkt'),
			{
				style: 'features',
				siteTheme: siteTheme,
				mapTheme: mapTheme,
			}
		);
		//preset.mapsight.featureSelections = {select: {features: [id]}};
	}

	return embed(
		{
			lang: lang,
			plugins: [
				[
					'presetHook',
					{
						afterCreate: function presetHook(context) {
							context.store.dispatch(
								set(['map', 'interactions'], {})
							);
						},
					},
				],
			],
			components: {
				MapOverlayBottomRight: null, // no zoom/geolocation buttons
			},
			uiState: {
				embeddedMap: true,
				layerSwitcher: {
					show: {
						internal: false,
						external: false,
					},
				},
				map: {
					show: true,
				},
				tagSwitcher: {
					show: false,
				},
			},
		},
		mapsightConfig
	);
}
