import {
	interactiveFeatures,
	metaData,
	metaDataWithLegend,
	osm,
	userGeolocation,
} from '@mapsight/ui/config/map/layers';

import {translate} from '../../helpers/i18n';

const osmAttribution = `${translate(
	'map_layers.map'
)}: &copy; <a href="http://www.openstreetmap.org/copyright" rel="external" target="_blank">${translate(
	'map_layers.osm_contributors'
)}</a>`;

const stadtAttribution =
	`${translate('map_layers.map')}: &copy; Stadt Braunschweig ` +
	`${translate(
		'map_layers.and'
	)} <a href="http://www.openstreetmap.org/copyright" rel="external" target="_blank">${translate(
		'map_layers.osm_contributors'
	)}</a>.`;

// TODO use translate for img urls, to allow for language specific legends
const streetLegend =
	'<div class="bs-ms3-legende bs-ms3-legende--stadtplan">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Spalte_1.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Spalte_2.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Spalte_3.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Spalte_4.png"><br>' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Verkehrslage.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Meldungen.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Parken.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/Stadtplan_Legende_Info.png">' +
	'</div>';
const bicycleLegend =
	'<div class="bs-ms3-legende bs-ms3-legende--fahrradkarte">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/FahrradStadtplan_Legende_SpalteLinks.png">' +
	'<img class="bs-ms3-legende__part" src="https://www4.braunschweig.de/tiles/legend/FahrradStadtplan_Legende_SpalteRechts.png">' +
	'</div>';
export default {
	street: osm(
		'/tiles/street/{z}/{x}/{y}.png',
		true,
		metaDataWithLegend(
			metaData(
				translate('map_layers.map_street'),
				stadtAttribution,
				true,
				false,
				true,
				translate('map_layers.map')
			),
			streetLegend
		)
	),

	// TODO: i18n title
	simple: osm(
		'/tiles/simple/{z}/{x}/{y}.png',
		false,
		metaData(
			translate('map_layers.map_simple'),
			stadtAttribution,
			false,
			false,
			true,
			translate('map_layers.map')
		)
	),

	aerial: osm(
		'/tiles/aerial/{z}/{x}/{y}.png',
		false,
		metaData(
			translate('map_layers.map_air'),
			stadtAttribution,
			true,
			false,
			true,
			translate('map_layers.map')
		)
	),
	bicycle: osm(
		'/tiles/bicycle/{z}/{x}/{y}.png',
		false,
		metaDataWithLegend(
			metaData(
				translate('map_layers.map_bicycle'),
				stadtAttribution,
				true,
				false,
				true,
				translate('map_layers.map')
			),
			bicycleLegend
		)
	),
	osm: osm(
		'/tiles/none/{z}/{x}/{y}.png',
		false,
		metaData(
			translate('map_layers.map_open_street_map'),
			osmAttribution,
			true,
			false,
			true,
			translate('map_layers.map')
		)
	),
	userGeolocation: userGeolocation(
		'userGeolocation',
		true,
		metaData(
			translate('map_layers.user_geolocation'),
			null,
			false,
			false,
			false,
			translate('map_layers.information')
		)
	),
	searchResult: interactiveFeatures(
		'searchResult',
		true,
		metaData(
			translate('map_layers.search_result'),
			null,
			false,
			false,
			false,
			translate('map_layers.information')
		)
	),
};
