import * as config from '@mapsight/ui/config';

import featureSources from './feature-sources';
import mapLayers from './map-layers';
import mapView from './map-view';

export default Object.assign(
	config.map(mapLayers, mapView),
	config.features(featureSources),
	config.featureList(null) // feature source is not known
);
