import {mapView, mapViewCenter, mapViewExtent} from '@mapsight/ui/config';

export default mapView(
	mapViewCenter(1171479, 6848253), // x, y ^= 52.2653825, 10.523575 (lat, lon)
	// Braunschweig: http://bboxfinder.com/#51.938934,9.858041,52.526000,11.144814
	// Deutschland: http://bboxfinder.com/#47.302488,5.988658,54.983104,15.016996
	mapViewExtent(666654, 5991588, 1671684, 7358588), // minx, miny, maxx, maxy
	14, // z
	10, // minZoom
	18 // maxZoom
);
