import proj4 from 'proj4';
import {shared as sharedIconImageCache} from 'ol/style/IconImageCache';

import {observeState} from '@mapsight/lib-redux/observe-state';

import {load, setData} from '@mapsight/core/lib/feature-sources/actions';
import {selectExclusively as selectFeatureExclusively} from '@mapsight/core/lib/feature-selections/actions';

import {
	FEATURE_SELECTIONS,
	FEATURE_SOURCES,
} from '@mapsight/ui/config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '@mapsight/ui/config/feature/selections';
import {
	plain,
	withFilter,
	xhrJson,
	xhrJsonRefreshing,
} from '@mapsight/ui/config/feature/sources';
import {
	features as featuresLayer,
	metaData,
} from '@mapsight/ui/config/map/layers';
import {setTagVisible} from '@mapsight/ui/store/actions';

import baseMapsightConfig from '../config';
import clusterFeaturesOptions from '../config/cluster-features';

import embed from './embed';
import {createId} from './utils';

sharedIconImageCache.setSize(200);

const fitAndKeepOptions = {
	duration: 1000,
	padding: [20, 20, 20, 20],
	keepZoom: true,
	skipIfInView: true,
};

// NOTE: This assumes layer and feature source have the exact same ID!!!
const createCombinedListSelector = (ids) =>
	function combinedListSelector(state) {
		let features = [];
		ids.forEach(function selectDataForCombinedListById(id) {
			if (
				state.map.layers[id].options.visible &&
				!state.featureSources[id].error &&
				state.featureSources[id].data
			) {
				features = [
					...features,
					...state.featureSources[id].data.features,
				];
			}
		});

		return {
			error: null,
			data: {
				type: 'FeatureCollection',
				features: features,
			},
		};
	};

export default function infosite({
	baseLayerName,
	startCoordinates,
	startZoom,
	centerMapsightIcon,
	centerLabel,

	enableMap = false,
	enableList = false,
	enableTagSwitcher = false,
	showCenter = false,
	layerSwitcherSetFeatureSourceId = false,

	enableListPagination = false,
	itemsPerListPage = 10,

	minZoom = undefined,
	maxZoom = undefined,
	isRoundTour = false,

	featureSources = [],
	initiallyVisibleTags = {},
	regions = {},

	lang = 'de',
	siteTheme = 'default',
	mapTheme = 'default',
}) {
	const mapsightConfig = {
		featureSources: {},
		map: {
			view: {
				center: startCoordinates
					? proj4('WGS84', 'EPSG:3857', startCoordinates)
					: undefined,
				zoom: startZoom ? parseInt(startZoom, 10) : undefined,
				minZoom: minZoom ? parseInt(minZoom, 10) : undefined,
				maxZoom: maxZoom ? parseInt(maxZoom, 10) : undefined,
			},
			layers: {},
		},
	};

	if (baseLayerName) {
		for (const layerId of Object.keys(baseMapsightConfig.map.layers)) {
			const layerConfig = baseMapsightConfig.map.layers[layerId];

			if (layerConfig.metaData && layerConfig.metaData.isBaseLayer) {
				const overrideVisible = layerId === baseLayerName;

				mapsightConfig.map.layers[layerId] = {
					options: {
						visible: overrideVisible,
					},
					metaData: {
						visibleInLayerSwitcher:
							overrideVisible ||
							layerConfig.metaData.visibleInLayerSwitcher,
					},
				};
			}
		}
	}

	if (startCoordinates && showCenter) {
		const id = createId();
		mapsightConfig.featureSources[id] = {
			...plain(),
			data: {
				type: 'FeatureCollection',
				features: [
					{
						id: id,
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: startCoordinates,
						},
						properties: {
							id: id,
							type: 'sharePoint',
							name: centerLabel,
							mapsightIconId: centerMapsightIcon || 'marker',
						},
					},
				],
			},
			ids: [id],
		};
		mapsightConfig.map.layers[id] = featuresLayer(
			id,
			true,
			false,
			metaData('Startpunkt'),
			{
				style: 'features',
				siteTheme: siteTheme,
				mapTheme: mapTheme,
			}
		);
		//preset.mapsight.featureSelections = {select: {features: [id]}};
	}

	let haveExternalLayerSwitcher = false;
	let firstTagSwitcherFeatureSourceId = undefined;
	const listFeatureSourceIds = [];
	const ownFeatureSourceIds = [];

	const handleFeatureSource = function (config) {
		let {id, url, timer = 60} = config;
		const {
			sourceType = 'url', // "own"|"url"
			mapsightIcon,
			mapsightIconUse,
			label,
			attribution,
			visibleInList,
			visibleInExternalLayerSwitcher,
			tagFilterable,
			layerVisible,
			layerInteractive,
			fitFeaturesInView = false,
			keepFeaturesInView = false,
			cluster = false,
			refresh = false,
		} = config;

		if (typeof window !== 'undefined' && sourceType === 'own') {
			const params = new URLSearchParams(window.location.search);
			params.append('sp:out', 'braunschweig.geoJSON');
			url = `${window.location.origin}${window.location.pathname}?${params}`;
		}

		// skip empty
		if (!url) {
			return;
		}

		const group =
			config.group && config.group.trim()
				? config.group.trim()
				: 'Informationen'; // TODO: i18n

		id = id || createId();
		// TODO: Handle mapsightIcon and mapsightIconUse

		let featureSource = xhrJson(url);
		if (refresh && timer) {
			if (!Number.isInteger(timer)) {
				timer = parseInt(timer, 10);
			}
			featureSource = xhrJsonRefreshing(url, timer * 1000);
		}

		const layer = featuresLayer(
			id,
			!!layerVisible,
			!!layerInteractive,
			metaData(
				label,
				attribution,
				false,
				!!visibleInExternalLayerSwitcher,
				false,
				group
			),
			{
				style: 'features',
				mapsightIconId: mapsightIcon,
				mapsightIconUse: mapsightIconUse,
				siteTheme: siteTheme,
				mapTheme: mapTheme,
			},
			{
				keepFeaturesInViewOptions: fitAndKeepOptions,
				fitFeaturesInViewOptions: fitAndKeepOptions,
			}
		);

		haveExternalLayerSwitcher =
			haveExternalLayerSwitcher || !!visibleInExternalLayerSwitcher;

		if (tagFilterable) {
			if (firstTagSwitcherFeatureSourceId !== undefined) {
				console.error(
					'FIXME: only one tagFilterable source supported but there are two or more defined'
				);
			} else {
				firstTagSwitcherFeatureSourceId = id;
				featureSource = withFilter(featureSource, 'tagFilter'); // TODO: Use exported constant from /ui
			}
		}

		if (sourceType === 'own') {
			ownFeatureSourceIds.push(id);
		}

		if (visibleInList) {
			listFeatureSourceIds.push(id);
		}

		if (cluster) {
			layer.options.source.options.clusterFeatures = true;
			layer.options.source.options.clusterFeaturesOptions =
				clusterFeaturesOptions;
		}

		if (isRoundTour) {
			// TODO: In die interactiveFeatures() Methode als Parameter?
			layer.options.source.options.fitFeaturesInViewSelections = [
				'select',
			];
			layer.options.source.options.fitFeaturesInViewOptions = {
				duration: 1000,
				padding: [0, 0, 0, 0],
				keepZoom: false,
				maxZoom: 17,
				skipIfInView: false,
			};
			layer.options = {
				...layer.options,
				updateWhileAnimating: true,
				updateWhileInteracting: true,
			};
		}

		if (fitFeaturesInView) {
			layer.options.source.options.fitAllFeaturesInView = true;

			// TODO: In die interactiveFeatures() Methode als Parameter?
			layer.options.source.options.fitFeaturesInViewOptions = {
				duration: 500,
				padding: [20, 20, 20, 20],
				keepZoom: false,
				maxZoom: 17,
				skipIfInView: false,
			};
			layer.options = {
				...layer.options,
				updateWhileAnimating: true,
				updateWhileInteracting: true,
			};
		}

		if (keepFeaturesInView) {
			layer.options.source.options.fitAllFeaturesInView = true;
			layer.options.source.options.keepAllFeaturesInView = true;

			// TODO: In die interactiveFeatures() Methode als Parameter?
			layer.options.source.options.keepFeaturesInViewOptions =
				layer.options.source.options.fitFeaturesInViewOptions = {
					duration: 500,
					padding: [20, 20, 20, 20],
					keepZoom: false,
					maxZoom: 17,
					skipIfInView: false,
				};
			layer.options = {
				...layer.options,
				updateWhileAnimating: true,
				updateWhileInteracting: true,
			};
		}

		mapsightConfig.featureSources[id] = featureSource;
		mapsightConfig.map.layers[id] = layer;
	};

	if (featureSources.length) {
		featureSources.forEach((config) => handleFeatureSource(config));
	}

	const useCombinedList = enableList && listFeatureSourceIds.length > 1;

	if (useCombinedList) {
		mapsightConfig.featureSources.combinedList = withFilter(
			{
				type: 'local',
				selector: createCombinedListSelector(listFeatureSourceIds),
				observeState: true,
			},
			'tagFilter'
		); // TODO: Use exported constant from /ui
	}

	if (enableList) {
		mapsightConfig.list = {
			featureSource: useCombinedList
				? 'combinedList'
				: listFeatureSourceIds[0],
		};
	}

	function hook({store}) {
		function handleChangeForCombinedList() {
			store.dispatch(
				load('featureSources', 'combinedList', {forceRefresh: true})
			);
		}

		if (useCombinedList) {
			listFeatureSourceIds.forEach(function observerStateForCombinedList(
				id
			) {
				observeState(
					store,
					(state) =>
						state.map.layers[id] &&
						state.map.layers[id].options.visible,
					handleChangeForCombinedList
				);
				observeState(
					store,
					(state) => state.featureSources[id],
					handleChangeForCombinedList
				);
			});
		}
		if (firstTagSwitcherFeatureSourceId && initiallyVisibleTags) {
			Object.keys(initiallyVisibleTags).forEach((group) => {
				initiallyVisibleTags[group].forEach((tag) => {
					store.dispatch(
						setTagVisible(
							firstTagSwitcherFeatureSourceId,
							group,
							tag,
							true
						)
					);
				});
			});
		}

		if (typeof window !== 'undefined') {
			// global function to enable cms/external js to select a feature
			window.mapsightSelectFeature = function globalMapsightSelectFeature(
				featureId
			) {
				store.dispatch(
					selectFeatureExclusively(
						FEATURE_SELECTIONS,
						FEATURE_SELECTION_SELECT,
						featureId
					)
				);
			};

			// global function to enable cms/external js to update own feature source data
			window.mapsightUpdateOwnFeatureData =
				function globalMapsightUpdateOwnFeatureData(data) {
					ownFeatureSourceIds.forEach((fSId) => {
						store.dispatch(setData(FEATURE_SOURCES, fSId, data));
					});
				};
		}
	}

	// TODO wenn die firstVisibleFeatureSourceId geladen ist, gleich [0] davon auswählen
	return embed(
		{
			lang: lang,
			plugins: [['presetHook', {afterCreate: hook}]],
			uiState: {
				embeddedMap: isRoundTour,
				layerSwitcher: {
					show: {
						internal: true,
						external: haveExternalLayerSwitcher,
					},
					internal: {
						layerIdsSelector: undefined, // implied default: layer ids of layers viewed in internal Selector
						grouped: true, // true: show layers without heading
					},
					external: {
						//layerIdsSelector: layerIdsExternalSwitcherSelector,
						grouped: true,
						setFeatureSourceId: layerSwitcherSetFeatureSourceId,
					},
				},
				tagSwitcher: {
					show:
						enableTagSwitcher &&
						firstTagSwitcherFeatureSourceId !== undefined,
					featureSourceId: firstTagSwitcherFeatureSourceId,
					sortTags: true,
				},
				map: {
					show: !!enableMap,
				},
				list: {
					selectionBehavior: {
						desktop: enableMap ? 'scrollToMap' : null,
						mobile: enableMap ? 'showInListOnly' : 'expandInList',
					},
					show: !!enableList,
					detailsInList: !enableMap || isRoundTour, // if true details will always be shown in list even if not on mobile
					showSelectedOnly: isRoundTour, // don't show list entries, only show the selected one (need some other kind of communication, cyclingControl or Icons on the map)
					selectOnClick: enableMap ? 'mainAndIcon' : false,
					deselectOnClick: false, // deselect on click to selected list item
					cyclingControl: isRoundTour, // show a control to select next or previous list entry
					sortControl: !isRoundTour, // show sort control icon
					filterControl: !isRoundTour, // show filter box
					paginationControl: enableListPagination, // show pagination control
					itemsPerPage: itemsPerListPage, // items per list page (if pagination control is active)
				},
				featureSelectionInfo: {
					stickyHeader: true, // make feature selection info header sticky?
				},
				regions: regions,
			},
		},
		mapsightConfig
	);
}
