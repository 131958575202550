import uiEmbed from '@mapsight/ui/embed/browser';
import ReactModal from 'react-modal';

import presetInfosite from './mapsight-bs/presets/infosite';
import presetInfositeRoundTour from './mapsight-bs/presets/infositeRoundTour';
import presetPointInput from './mapsight-bs/presets/pointInput';
import presetStadtplan from './mapsight-bs/presets/stadtplan';
import presetStaticMap from './mapsight-bs/presets/staticMap';

ReactModal.setAppElement(
	document.querySelector('.bs-site-wrapper, .ms3-wrapper')
);

window.mapsightUi = window.mapsightUi || {};
window.mapsightUi.preset = {
	infosite: (element, config) => uiEmbed(element, presetInfosite(config)),
	infositeRoundTour: (element, config) =>
		uiEmbed(element, presetInfositeRoundTour(config)),
	pointInput: (element, config) => uiEmbed(element, presetPointInput(config)),
	stadtplan: (element, sharePoint) =>
		uiEmbed(element, presetStadtplan({sharePoint: sharePoint})),
	staticMap: (element, config) => uiEmbed(element, presetStaticMap(config)),
};
