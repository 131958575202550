const DATA_ATTR = 'data-apple-href';
const IS_APPLE =
	typeof navigator !== undefined &&
	navigator.platform &&
	/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

function appleHrefAttribute(element) {
	element.setAttribute('href', element.getAttribute(DATA_ATTR));
}

export function render() {
	if (IS_APPLE) {
		document.querySelectorAll(`[${DATA_ATTR}]`).forEach(appleHrefAttribute);
	}
}
