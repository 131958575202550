import React, {Fragment, useReducer} from 'react';
import proj4 from 'proj4';

import {
	plain,
	xhrJson,
	xhrJsonRefreshing,
} from '@mapsight/ui/config/feature/sources';
import {mapViewCenter} from '@mapsight/ui/config';
import {features, metaData} from '@mapsight/ui/config/map/layers';
import SharePositionLinkButton from '@mapsight/ui/components/map-overlay/share-position-link-button';
import MeasureDistanceButton from '@mapsight/ui/components/map-overlay/measure-distance-button';

import createShareLinkPlugin from '@mapsight/ui/plugins/browser/share-position-link';
import createMeasureDistancePlugin from '@mapsight/ui/plugins/common/measure-distance';

import baseMapViewConfig from '../config/map-view';
import clusterFeaturesOptions from '../config/cluster-features';

import embed from './embed';

const PLUGIN_NAME_SHARE_LINK = 'sharePositionLink';
const PLUGIN_NAME_MEASURE_DISTANCE = 'measureDistance';

const fitAndKeepOptions = {
	duration: 1000,
	padding: [20, 20, 20, 20],
	keepZoom: true,
	skipIfInView: true,
};

// full stadtplan preset

export const featureSources = {
	// cms part 1
	hotels: xhrJson('/tourismus/uebernachten/karte.geojson'),
	sehenswuerdigkeiten: xhrJson(
		'/tourismus/ueber-braunschweig/sehenswuerdigkeiten/karte.geojson'
	),
	museen: xhrJson('/geojson/museen.geojson'),
	theater: xhrJson('/geojson/theater.geojson'),

	// pulp
	traffic: xhrJsonRefreshing(
		'/apps/pulp/result/traffic.geojson',
		2 * 60 * 1000
	),
	trafficMessages: xhrJsonRefreshing(
		'/apps/pulp/result/traffic-messages.geojson',
		10 * 60 * 1000
	),
	trafficMessagesPreview: xhrJsonRefreshing(
		'/apps/pulp/result/traffic-messages-preview.geojson',
		30 * 60 * 1000
	),
	parkhaeuser: xhrJsonRefreshing(
		'/apps/pulp/result/parkhaeuser.geojson',
		2 * 60 * 1000
	),
	chargingStations: xhrJson('/apps/pulp/result/charging-stations.geojson'),

	// static files
	oepnvStops: xhrJson('/geojson/oepnv-stops.geojson'),
	oepnvLines: xhrJson('/geojson/oepnv-lines.geojson'),
	sportanlagen: xhrJson('/geojson/sportanlagen.geojson'),

	// cms part 2
	parkplaetze: xhrJson('/geojson/parkplaetze.geojson'),
	parknride: xhrJson('/geojson/parknride.geojson'),
	busparkplaetze: xhrJson('/geojson/busparkplaetze.geojson'),
	motorradstellplaetze: xhrJson('/geojson/motorradstellplaetze.geojson'),
	behindertenparkplaetze: xhrJson('/geojson/behindertenparkplaetze.geojson'),
	schulen: xhrJson(
		'/leben/schule_bildung/schulportal/uebersicht_schulen.geojson'
	),
	// Ist jetzt eigentlich eine externe Anwendung. Auftrag zur Integration fehlt: https://kita-planer.kdo.de/braunschweig-elternportal/elternportal/de/einrichtungen/karte
	//kindertagesstaetten: xhrJson('/geojson/kindertagesstaetten.geojson'),
	kliniken: xhrJson('/geojson/kliniken.geojson'),
	forschungseinrichtungen: xhrJson(
		'/wirtschaft_wissenschaft/wissenschaftsportal/forschungslandschaft/index.geojson'
	),
	bibliotheken: xhrJson('/geojson/bibliotheken.geojson'),
	religion: xhrJson('/leben/kirchen_religion/index.geojson'),
	parkanlagen: xhrJson('/leben/im_gruenen/parkanlagen.geojson'),
	wertstoffcontainer: xhrJson(
		'/leben/wohnen_energie_abfall/usbs/wsc/wertstoffcontainer.geojson'
	),
};

const presetModifiers = {
	// location hash => presetModifier
	'#verkehr': 'verkehr', // meldungen, meldungen vorschau, verkehrslage, haltestellen,
	'#oepnv': 'oepnv',
	'#parken': 'parken',
	'#reisebus': 'reisebus',
	'#pnr': 'pnr',
	'#behindertenparken': 'behindertenparken',
	'#baustellen': 'baustellen', // meldungen, meldungen vorschau, verkehrslage
	'#laden': 'laden', // chargingStations
};

const initialListFeatureSources = {
	// preset => featureSourceId
	verkehr: 'trafficMessages', // meldungen, meldungen vorschau, verkehrslage, haltestellen,
	oepnv: 'oepnvLines',
	parken: 'parkhaeuser',
	pnr: 'parknride',
	behindertenparken: 'behindertenparkplaetze',
	reisebus: 'busparkplaetze',
	baustellen: 'trafficMessages', // meldungen, meldungen vorschau, verkehrslage
	laden: 'chargingStations',
};

const initialZooms = {
	// preset => zoom level
	verkehr: 13,
	oepnv: 13,
	pnr: 12,
	baustellen: 13,
};

function useToggleGroup(names, initialActiveName) {
	const createState = (activeName) =>
		Object.fromEntries(names.map((name) => [name, activeName === name]));
	return useReducer(
		(_, activeName) => createState(activeName),
		createState(initialActiveName)
	);
}

export default function stadtplan(config = {}) {
	const {
		lang = 'de',
		sharePoint = null, // TODO: Update CMS side! sharePoint is not an option anymore but
	} = config || {};

	const presetModifier =
		presetModifiers[
			typeof window !== 'undefined' &&
				window.location &&
				window.location.hash
		] || null; // TODO: Get preset modifier on SSR
	const initialListFeatureSource =
		initialListFeatureSources[presetModifier] || null;
	const initialZoom = initialZooms[presetModifier] || baseMapViewConfig.zoom;

	const stadtplanLayer = (
		fSId,
		title,
		group,
		cluster = false,
		preset = false,
		style = 'features'
	) => {
		let visible = false;

		if (preset) {
			if (presetModifier === preset) {
				visible = true;
			} else if (
				Array.isArray(preset) &&
				preset.indexOf(presetModifier) > -1
			) {
				visible = true;
			}
		}

		const layer = features(
			fSId,
			visible,
			true,
			metaData(title, null, false, true, false, group),
			style,
			{
				keepFeaturesInViewOptions: fitAndKeepOptions,
				fitFeaturesInViewOptions: fitAndKeepOptions,
			}
		);
		if (cluster) {
			layer.options.source.options.clusterFeatures = true;
			layer.options.source.options.clusterFeaturesOptions =
				clusterFeaturesOptions;
		}

		return layer;
	};

	const mapsightConfig = {
		featureSources: featureSources,

		map: {
			view: {zoom: initialZoom},
			layers: {
				// make simple base layer style available in layer switcher
				simple: {metaData: {visibleInLayerSwitcher: true}},

				// pois general
				hotels: stadtplanLayer(
					'hotels',
					'Hotels',
					'Allgemein',
					true,
					false,
					{
						style: 'features',
						mapsightIconId: 'hotel',
					}
				),
				sehenswuerdigkeiten: stadtplanLayer(
					'sehenswuerdigkeiten',
					'Sehenswürdigkeiten',
					'Allgemein',
					true,
					false,
					{
						style: 'features',
						mapsightIconId: 'sehenswuerdigkeit',
					}
				),
				museen: stadtplanLayer(
					'museen',
					'Museen',
					'Allgemein',
					true,
					false,
					{
						style: 'features',
						mapsightIconId: 'museum',
					}
				),
				theater: stadtplanLayer(
					'theater',
					'Theater',
					'Allgemein',
					true,
					false,
					{
						style: 'features',
						mapsightIconId: 'theater',
					}
				),

				// traffic / LOS
				traffic: features(
					'traffic',
					presetModifier === 'baustellen' ||
						presetModifier === 'verkehr',
					true,
					metaData(
						'Verkehrslage',
						null,
						false,
						true,
						false,
						'Verkehr'
					),
					'traffic'
				),

				// traffic messages
				trafficMessages: stadtplanLayer(
					'trafficMessages',
					'Meldungen',
					'Verkehr',
					false,
					['baustellen', 'verkehr']
				),
				trafficMessagesPreview: stadtplanLayer(
					'trafficMessagesPreview',
					'Meldungen (Vorschau)',
					'Verkehr',
					false,
					['baustellen', 'verkehr']
				),

				// traffic pois
				chargingStations: stadtplanLayer(
					'chargingStations',
					'Ladesäulen',
					'Verkehr',
					true,
					'laden',
					{
						style: 'features',
						mapsightIconId: 'charging-station',
					}
				),

				oepnvStops: stadtplanLayer(
					'oepnvStops',
					'Haltestellen',
					'Verkehr',
					false,
					['oepnv', 'verkehr']
				),
				oepnvLines: stadtplanLayer(
					'oepnvLines',
					'Bus- und Bahnlinien',
					'Verkehr',
					false,
					['oepnv', 'verkehr'],
					{
						style: 'features',
						onlyShowSelected: true,
					}
				),

				parkhaeuser: stadtplanLayer(
					'parkhaeuser',
					'Parkhäuser',
					'Parken',
					false,
					'parken'
				),
				parkplaetze: stadtplanLayer(
					'parkplaetze',
					'Parkplätze',
					'Parken',
					false,
					'parken'
				),
				parknride: stadtplanLayer(
					'parknride',
					'Park & Ride',
					'Parken',
					false,
					['parken', 'pnr']
				),
				busparkplaetze: stadtplanLayer(
					'busparkplaetze',
					'Busparkplätze',
					'Parken',
					false,
					['parken', 'reisebus']
				),
				motorradstellplaetze: stadtplanLayer(
					'motorradstellplaetze',
					'Motorradstellplätze',
					'Parken',
					false
				),
				behindertenparkplaetze: stadtplanLayer(
					'behindertenparkplaetze',
					'Behindertenparkplätze',
					'Parken',
					false,
					'behindertenparken'
				),
				schulen: stadtplanLayer('schulen', 'Schulen', 'Weitere Daten'),
				//kindertagesstaetten: features('kindertagesstaetten', false, true, metaData('Kindertagesstätten', null, false, true, false, 'Weitere Daten')),
				kliniken: stadtplanLayer(
					'kliniken',
					'Krankenhäuser/Kliniken',
					'Weitere Daten'
				),
				forschungseinrichtungen: stadtplanLayer(
					'forschungseinrichtungen',
					'Forschungslandschaft',
					'Weitere Daten'
				),
				bibliotheken: stadtplanLayer(
					'bibliotheken',
					'Bibliotheken/Archive',
					'Weitere Daten'
				),
				religion: stadtplanLayer(
					'religion',
					'Religion',
					'Weitere Daten'
				),
				parkanlagen: stadtplanLayer(
					'parkanlagen',
					'Parks',
					'Weitere Daten'
				),
				sportanlagen: stadtplanLayer(
					'sportanlagen',
					'Sportanlagen',
					'Weitere Daten'
				),
				wertstoffcontainer: stadtplanLayer(
					'wertstoffcontainer',
					'Wertstoffcontainer',
					'Weitere Daten'
				),
			},
		},

		list: {
			featureSource: initialListFeatureSource,
		},
	};

	function geoJsonPointToMapViewCoordinates(pointGeometry) {
		return proj4('WGS84', 'EPSG:3857', pointGeometry.coordinates);
	}

	if (sharePoint) {
		mapsightConfig.featureSources = {
			...mapsightConfig.featureSources,
			sharePoint: {
				...plain(),
				data: {
					type: 'FeatureCollection',
					features: [
						{
							...sharePoint,
							properties: {
								name: 'Markierung', // TODO: i18n
								mapsightIconId: 'marker',
								...(sharePoint.properties || {}),
							},
						},
					],
				},
				ids: [sharePoint.id],
			},
		};
		mapsightConfig.map = {
			...mapsightConfig.map,
			view: {
				center: mapViewCenter(
					...geoJsonPointToMapViewCoordinates(sharePoint.geometry)
				),
				zoom: 16,
			},
			layers: {
				...mapsightConfig.map.layers,
				sharePoint: features(
					'sharePoint',
					true,
					true,
					metaData(
						'Zusätzliche Daten',
						null,
						false,
						false,
						false,
						'Weitere Daten'
					)
				),
			},
		};
	}

	return embed(
		{
			lang: lang,
			plugins: [
				[
					'sharePositionLink',
					createShareLinkPlugin({
						name: PLUGIN_NAME_SHARE_LINK,
						markerName: 'Markierung',
					}),
				],
				[
					'measureDistance',
					createMeasureDistancePlugin({
						name: PLUGIN_NAME_MEASURE_DISTANCE,
					}),
				],
			],
			components: {
				MapOverlayTopLeft({fallback}) {
					const [toggleStates, setActive] = useToggleGroup([
						PLUGIN_NAME_MEASURE_DISTANCE,
						PLUGIN_NAME_SHARE_LINK,
					]);

					return (
						<>
							{fallback}
							<SharePositionLinkButton
								opened={
									toggleStates[PLUGIN_NAME_SHARE_LINK] ??
									false
								}
								onOpenedChange={(value) =>
									setActive(
										value
											? PLUGIN_NAME_SHARE_LINK
											: undefined
									)
								}
								pluginName={PLUGIN_NAME_SHARE_LINK}
							/>
							<MeasureDistanceButton
								opened={
									toggleStates[
										PLUGIN_NAME_MEASURE_DISTANCE
									] ?? false
								}
								onOpenedChange={(value) =>
									setActive(
										value
											? PLUGIN_NAME_MEASURE_DISTANCE
											: undefined
									)
								}
								pluginName={PLUGIN_NAME_MEASURE_DISTANCE}
							/>
						</>
					);
				},
			},
			uiState: {
				searchInMap: true, // enable address search in map overlay
				layerSwitcher: {
					show: {
						internal: true,
						external: true,
					},
					internal: {
						//layerIdsSelector: undefined, // implied default: layer ids of layers viewed in internal Selector
						grouped: true,
					},
					external: {
						//layerIdsSelector: layerIdsExternalSwitcherSelector,
						grouped: true,
						setFeatureSourceId: true,
					},
				},
				map: {
					show: true,
				},
				list: {
					show: true,
					detailsInList: false, // if true details will always be shown in list even if not on mobile
					showSelectedOnly: false, // don't show list entries, only show the selected one (need some other kind of communitcation, cyclingControl or Icons on the map)
					deselectOnClick: false, // deselect on click to selected list item
					cyclingControl: false, // show a control to select next or previous list entry
					sortControl: true, // show sort control icon
					filterControl: true, // show filter box
				},
				featureSelectionInfo: {
					stickyHeader: true, // make feature selection info header sticky?
				},
			},
		},
		mapsightConfig
	);
}
